import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const language = localStorage.language  || "en-US";

const maps = {
  "zh-CN": "cn",
  "en-US": "en"
}

const routes = [
  { 
    path: '/',
    name: 'Main',
    component: () => import('../views/main'),
    redirect: "/" + maps[language],
    children: [
      {
        path: '/:language',
        name: 'Main',
        component: () => import('../views/main')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
