import enLocale from 'vui-design/src/locale/lang/en-US'

export default{
  ...enLocale,
  lang:'en',
  nav: {
    home: require('@/assets/navigation-bar/home_en.png'),
    news: require('@/assets/navigation-bar/news_en.png'),
    characters: require('@/assets/navigation-bar/characters_en.png'),
    features: require('@/assets/navigation-bar/features_en.png'),
    benefits: require('@/assets/navigation-bar/benefits_en.png'),
    value: require('@/assets/navigation-bar/value_en.png')
  },
  navRight: {
    news: require('@/assets/part-01/right-news_en.png'),
    characters: require('@/assets/part-01/right-charecters_en.png'),
    features: require('@/assets/part-01/right-features_en.png'),
    benefits: require('@/assets/part-01/right-benefits_en.png'),
    value: require('@/assets/part-01/right-vaule_en.png')
  },
  home: {
    glide: require('@/assets/part-01/glide_en.png'),
    text: require('@/assets/part-01/text_en.png'),
    text1: require('@/assets/part-01/popup-text_en.png')
  },
  news: {
    title: require('@/assets/part-01/title_en.png') ,
    like: require('@/assets/part-02/btn-like_en.png'),
    share: require('@/assets/part-02/btn-share_en.png'),
    tip: 'Tip: Please sign in to facebook on another page in this browser.'
  },
  characters: {
    title: require('@/assets/part-02/title_en.png'),
    name:{
      name1: require('@/assets/character/man01-name_en.png'),
      name2: require('@/assets/character/man02-name_en.png'),
      name3: require('@/assets/character/man03-name_en.png'),
      name4: require('@/assets/character/man04-name_en.png'),
      name5: require('@/assets/character/man05-name_en.png'),
      name6: require('@/assets/character/woman01-name_en.png'),
      name7: require('@/assets/character/woman02-name_en.png'),
      name8: require('@/assets/character/woman03-name_en.png'),
      name9: require('@/assets/character/woman04-name_en.png'),
      name10: require('@/assets/character/woman05-name_en.png'),
      name11: require('@/assets/character/animal01-name_en.png'),
      name12: require('@/assets/character/animal02-name_en.png'),
      name13: require('@/assets/character/animal03-name_en.png'),
      name14: require('@/assets/character/animal04-name_en.png'),
      name15: require('@/assets/character/animal05-name_en.png'),
    },
    des:{
      des1: require('@/assets/character/man01-des_en.png'),
      des2: require('@/assets/character/man02-des_en.png'),
      des3: require('@/assets/character/man03-des_en.png'),
      des4: require('@/assets/character/man04-des_en.png'),
      des5: require('@/assets/character/man05-des_en.png'),
      des6: require('@/assets/character/woman01-des_en.png'),
      des7: require('@/assets/character/woman02-des_en.png'),
      des8: require('@/assets/character/woman03-des_en.png'),
      des9: require('@/assets/character/woman04-des_en.png'),
      des10: require('@/assets/character/woman05-des_en.png'),
      des11: require('@/assets/character/animal01-des_en.png'),
      des12: require('@/assets/character/animal02-des_en.png'),
      des13: require('@/assets/character/animal03-des_en.png'),
      des14: require('@/assets/character/animal04-des_en.png'),
      des15: require('@/assets/character/animal05-des_en.png'),
    },
  },
  features: {
    title: require('@/assets/part-04/title_en.png'),
    bg: require('@/assets/part-04/bg-title_en.png')
  },
  footer: {
    title: require('@/assets/bottom-bar/title_en.png'),
    user: require('@/assets/bottom-bar/user_en.png'),
    privacy: require('@/assets/bottom-bar/privacy_en.png'),
    content: require('@/assets/bottom-bar/content_en.png')
  }
}