import Vue from 'vue';
import App from './App.vue';
import router from './router';
import "vui-design/dist/style/vui-design.css";
import VuiDesign from "vui-design";
import i18n from '@/i18n/index';

Vue.config.productionTip = false
Vue.use(VuiDesign);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
