import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './zh'
import en from './en'
import locale from 'vui-design/src/locale'

Vue.use(VueI18n)

const messages = {
  "zh-CN": zh,
  "en-US": en
};
const i18n = new VueI18n({
  locale: 'zh-CN',
  messages
});
locale.i18n((key, value) => i18n.t(key, value))

export default i18n;
