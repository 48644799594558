import zhLocale from 'vui-design/src/locale/lang/zh-CN'

export default{
  ...zhLocale,
  lang:'zh',
  nav: {
    home: require('@/assets/navigation-bar/home_zh.png'),
    news: require('@/assets/navigation-bar/news_zh.png'),
    characters: require('@/assets/navigation-bar/charecters_zh.png'),
    features: require('@/assets/navigation-bar/features_zh.png'),
    benefits: require('@/assets/navigation-bar/benefits_zh.png'),
    value: require('@/assets/navigation-bar/value_zh.png')
  },
  navRight: {
    news: require('@/assets/part-01/right-news_zh.png'),
    characters: require('@/assets/part-01/right-charecters_zh.png'),
    features: require('@/assets/part-01/right-features.png'),
    benefits: require('@/assets/part-01/right-benefits_zh.png'),
    value: require('@/assets/part-01/right-value_zh.png')
  },
  home: {
    glide: require('@/assets/part-01/glide_zh.png'),
    text: require('@/assets/part-01/text_zh.png'),
    text1: require('@/assets/part-01/popup-text_zh.png')
  },
  news: {
    title: require('@/assets/part-02/title_zh.png'),
    like: require('@/assets/part-02/btn-like.png'),
    share: require('@/assets/part-02/btn-share.png'),
    tip: '提示：請在此瀏覽器的其他頁面登錄facebook後方可顯示.'
  },
  characters: {
    title: require('@/assets/part-03/title_zh.png'),
    name:{
      name1: require('@/assets/character/man01-name.png'),
      name2: require('@/assets/character/man02-name.png'),
      name3: require('@/assets/character/man03-name.png'),
      name4: require('@/assets/character/man04-name.png'),
      name5: require('@/assets/character/man05-name.png'),
      name6: require('@/assets/character/woman01-name.png'),
      name7: require('@/assets/character/woman02-name.png'),
      name8: require('@/assets/character/woman03-name.png'),
      name9: require('@/assets/character/woman04-name.png'),
      name10: require('@/assets/character/woman05-name.png'),
      name11: require('@/assets/character/animal01-name.png'),
      name12: require('@/assets/character/animal02-name.png'),
      name13: require('@/assets/character/animal03-name.png'),
      name14: require('@/assets/character/animal04-name.png'),
      name15: require('@/assets/character/animal05-name.png'),
    },
    des:{
      des1: require('@/assets/character/man01-des.png'),
      des2: require('@/assets/character/man02-des.png'),
      des3: require('@/assets/character/man03-des.png'),
      des4: require('@/assets/character/man04-des.png'),
      des5: require('@/assets/character/man05-des.png'),
      des6: require('@/assets/character/woman01-des.png'),
      des7: require('@/assets/character/woman02-des.png'),
      des8: require('@/assets/character/woman03-des.png'),
      des9: require('@/assets/character/woman04-des.png'),
      des10: require('@/assets/character/woman05-des.png'),
      des11: require('@/assets/character/animal01-des.png'),
      des12: require('@/assets/character/animal02-des.png'),
      des13: require('@/assets/character/animal03-des.png'),
      des14: require('@/assets/character/animal04-des.png'),
      des15: require('@/assets/character/animal05-des.png'),
    },
  },
  features: {
    title: require('@/assets/part-04/title_zh.png'),
    bg: require('@/assets/part-04/bg-title.png')
  },
  footer: {
    title: require('@/assets/bottom-bar/follow_zh.png'),
    user: require('@/assets/bottom-bar/user_zh.png'),
    privacy: require('@/assets/bottom-bar/privacy.png'),
    content: require('@/assets/bottom-bar/content.png')
  },
}