<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
*{
  width:100%;
  padding:0;
  margin:0 auto;
}
</style>
